
export const getPopupInstance = () => {
  return new Promise((resolve) => {
    import(
      'public/src/pages/goods_detail_v2/components/RecommendRelatives/GetTheLookNew/main.js'
    ).then(res => {
      resolve(res.default)
    })
  })
}
