import commentAdapter from 'public/src/pages/goods_detail_v2/utils/adapters/comment.js'
import { makeFreeList } from './utils.js'
import schttp from 'public/src/services/schttp'

export default {
  namespaced: true,
  state: {
    comment: {},
    only_show_report: false,
    default_lang: '',
    is_auto_translate: false
  },
  getters: {
    // TODO Delete - 后期pc开始中间层融合时，此处将替换
    bffComment(state, getters, rootState) {
      return rootState.realtimeBffInfo?.comment || {}
    },
    /** 首屏评论 */
    comments(state, getters) {
      const data = commentAdapter.abcCommentAdapter({
        info: {
          comment_info: getters.bffComment.product_comments 
        }
      }) || []
      return data.info.commentInfo || []
    },
    /** 首屏试用报告 */
    trail_data(state, getters) {
      const { bffComment } = getters
      const data = commentAdapter.freeTrailAdapter({
        info: {
          free_trial_list: bffComment.trail_data?.reportList 
        }
      }) || []
      return makeFreeList(data.info.free_trial_list) || []
    },
    /** 首屏站外评论 */
    store_product_comments(state, getters) {
      const { bffComment } = getters
      const store_product_comments = bffComment.store_product_comments || []
      const out_store_review_list = store_product_comments.map((comment, index) => ({
        ...(comment || {}),
        isOutStoreReview: true,
        isFirstOne: index === 0,
        comment_id: comment?.store_comment_id || 0,
        // page: Math.ceil((index + 1) / 20)
      }))

      return out_store_review_list || []
    },
    comments_overview(state, getters) {
      const { bffComment } = getters

      return bffComment.comments_overview || {}
    },
    comment_tags(state, getters) {
      const { bffComment } = getters

      return bffComment.comment_tags || []
    },
    translate_language(state, getters, rootState) {
      const { bffComment } = getters
      return bffComment.translate_language || []
    },
    language_config_set(state, getters, rootState, rootGetters) {
      const { translate_language } = getters
      return translate_language.map(item => item.translate_language) || []
    },
    // 是否批量翻译
    is_batch_translate(state, getters, rootState, rootGetters) {
      const { bffComment } = getters
      const { screenAbtConfig } = rootGetters
      const isBatchTranslate = +bffComment.trans_mode === 1
      const isNotBatchTranslate = screenAbtConfig?.Reviewchange?.param?.Batch_translate === 'No'
      return isBatchTranslate && !isNotBatchTranslate
    },
  },
  mutations: {
    update_only_show_report(state, payload) {
      state.only_show_report = payload
    },
    update_default_lang(state, payload) {
      state.default_lang = payload
    },
    update_is_auto_translate(state, payload) {
      state.is_auto_translate = payload
    },
  },
  actions: {
    // 点赞
    async like_click_action(
      { rootState },
      { comment_id, current_member_zan, sku }
    ) {
      let url = ''
      let method = 'GET'
      let params = {}
      const { goods_sn } = rootState.productIntroData?.detail || {}
      const isRp = comment_id.toString().indexOf('rp') > -1
      const currThumbsUp = Number(current_member_zan) == 1 ? 0 : 1
      if (isRp) {
        url = 'user/trial/like_report'
        params = {
          report_id: comment_id.replace('rp', ''),
          action: currThumbsUp,
          sku: sku || goods_sn,
        }
        method = 'POST'
      } else {
        url = '/product/like_goods_review'
        params = {
          comment_id,
          thumbs_up: currThumbsUp,
          sku: sku || goods_sn,
        }
      }
      return await schttp({ 
        url,
        params,
        useBffApi: true,
        method
      })
    },
    // 批量翻译
    async fetch_batch_translate({ state, rootGetters, rootState }, comment_info_data = []) {
      const comment_infos = []
      const { default_lang } = state
      comment_info_data.length &&
        comment_info_data.forEach(i => {
          if (!i.reportId && (!i.translateText || i.current_lang !== default_lang)) {
            comment_infos.push({
              comment_id: i.comment_id,
              src_language: i.language_flag
            })
          }
        })
      if (comment_infos.length) {
        const { productRelationID } = rootState.productIntroData?.detail || {}
        let data = {}
        const comment_ids = []
        const srcLanguages = []
        comment_infos.slice(0, 20).forEach((item) => {
          const { comment_id, src_language } = item || {}
          comment_ids.push(comment_id)
          srcLanguages.push(src_language)
        })
        data = await schttp({
          url: '/product/comment/batch_translate',
          method: 'POST',
          useBffApi: true,
          params: {
            dest_language: default_lang,
            goods_spu: productRelationID,
            comment_ids: comment_ids.join(','),
            src_languages: srcLanguages.join(','),
          }
        })

        if (data.code == 0 && data.info?.comment_infos?.length) {
          let translateComments = data.info.comment_infos

          let commentInfoDataForAutoTranslate =
            Array.isArray(comment_info_data) &&
            comment_info_data.map(i => {
              const hitTransComment = translateComments.find(j => j.comment_id === i.comment_id)
              const translateText = hitTransComment?.dest_text || i.current_lang !== default_lang && default_lang === i.language_flag && i.content || ''
              const translateTags = hitTransComment?.dest_content_tag || []
              const translateSelectTags = hitTransComment?.dest_select_tag || []
              let obj = {
                ...i,
                showOrigin: typeof i.showOrigin === undefined ? i.showOrigin : !this.isAutoTranslate
              }
              if (obj.translateText === undefined || translateText) {
                obj.translateText = translateText
                obj.translateTags = translateTags
                obj.translateSelectTags = translateSelectTags
                obj.current_lang = default_lang
              }
              return obj
            })
          return commentInfoDataForAutoTranslate
        }
        return comment_info_data
      }
      return comment_info_data
    },
  }
}
