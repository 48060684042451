import { transformImg } from '@shein/common-function'
export default {
  namespaced: true,
  state: {
    findSimilarFloatInfo: {
      show: 0,
      products: [],
      from: '',
      filterSize: '',
      mode: '',
      hasMoreProducts: false,
      showLoginModal: null,
    },
  },
  getters: {
    language(state, getters, rootState) {
      return rootState.language
    },
    showSimilarBtn(state, getters, rootState, rootGetters) {
      const { skuInfo } = rootState
      const { isAllSoldOut } = rootGetters
      return Number(skuInfo.stock) === 0 || isAllSoldOut
    },
    soldOutImg(state, getters, rootState) {
      const detailMainImg = rootState.productIntroData.detail.goods_thumb
      return transformImg({ img: detailMainImg })
    },
    currentGoods(state, getters, rootState) {
      return rootState.productIntroData.detail
    },
    LAZY_IMG(state, getters, rootState) {
      return rootState.LAZY_IMG || ''
    },
  },
  mutations: {
    updateFindSimilarFloatInfo(state, payload = {}) {
      state.findSimilarFloatInfo = Object.assign(
        state.findSimilarFloatInfo,
        payload
      )
    },
  },
}
