import { createStore } from 'vuex'
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import modules from './modules'

const goodsDetailStore = () => createStore({
  modules,
  state,
  getters,
  actions,
  mutations,
})

export { goodsDetailStore }
